export const dataCategoria = [

    {
        id: 1,
        categoria: 'internet',
        name:'MAESTROS/ESTUDIANTES.',
        descripcion:'El funcionamiento de la red MAESTROS/ESTUDIANTES no está siendo correcto.',
    },
    {
        id: 2,
        categoria: 'internet',
        name:'CONECTIVIDAD CÓRDOBA.',
        descripcion:'La red de nombre CONECTIVIDAD CÓRDOBA no está funcionando de manera normal.',
    },
    {
        id: 3,
        categoria: 'internet',
        name:'La escuela no tiene internet.',
        descripcion:'No posee conexión a internet.',
    },
    {    
        id: 4,
        categoria: 'asistencia',
        name:'Instalación office.',
        descripcion:'Solicita información sobre el proceso de instalación del paquete de Microsoft Office de acceso con cuentas provistas por el ministerio de educacion -"@me.cba.gov.ar.',
    },
    {
        id: 5,
        categoria: 'asistencia',
        name:'Desbloqueo netbooks.',
        descripcion:'Solicita asistencia en el desbloqueo de netbooks.',
    },
    {
        id: 6,
        categoria: 'asistencia',
        name:'Equipos con desperfectos.',
        descripcion:'Solicita asistencia técnica en equipos.',
    },
    {
        id: 7,
        categoria: 'correo',
        name:'Olvide mi contraseña.',
        descripcion:'***.',
    },
    {
        id: 8,
        categoria: 'correo',
        name:'Nuevo/s usuarios.',
        descripcion:'Solicita la creación de nuevos usuarios.',
    },
    {
        id: 9,
        categoria: 'correo',
        name:'Modificar información de una cuenta.',
        descripcion:'Modificar información de cuenta/s.',
    },
    {
        id: 10,
        categoria: 'telefonia',
        name:'No hay tono.',
        descripcion:'El teléfono no tiene tono al levantarlo.',
    },
    {
        id: 11,
        categoria: 'telefonia',
        name:'Habilitar llamadas a celulares.',
        descripcion:'Solicita habilitar las llamadas a celular.',
    },
    {
        id: 12,
        categoria: 'telefonia',
        name:'No hay servicio.',
        descripcion:'No posee servicio telefónico.',
    },
    {
        id: 13,
        categoria: 'telefonia',
        name:'Traslado de linea.',
        descripcion:'Solicita el traslado de la línea telefónica.',
    },

    {
        id: 14,
        categoria: 'correo',
        name:'Modificar datos de contacto (teléfono/email alternativo)',
        descripcion:'Solicita modificar datos de contacto (teléfono/email alternativo)',
    },
    
];
