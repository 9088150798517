
import React, { useEffect, useState } from 'react'
import { BrowserRouter,Routes,Route, json} from 'react-router-dom';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';  // Importa saveAs

import {Principal} from './pages/Principal'
import {Asistencias} from './pages/Asistencias'
import {CasoNuevo} from './pages/CasoNuevo'
import {MostrarCaso} from './pages/MostrarCaso'
import {GestionBlanqueo} from './pages/GestionBlanqueo.js'
import {Template} from './components/template'
import './App.css'

import { PageLayout } from "./components/PageLayout";

import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";

import { SignInButton } from "./components/SignInButton";
import { SignOutButton } from "./components/SignOutButton";

import FormularioContacto from './components/FormularioContacto.js';



import logo_pipal from './assets/img/logo_pipal.png';
  

export default  function App() {

  const [CUE, setCUE] = useState('');
  const [datos, setDatos] = useState([]);

  const [PASO1, setPASO1] = useState(true);
  const [PASO2, setPASO2] = useState(false);
  const [PASO3, setPASO3] = useState(false);
  const [PASO4, setPASO4] = useState(false);
  const [PASO5, setPASO5] = useState(false);
  const [PASO6, setPASO6] = useState(false);

  const [emailData, setEmailData] = useState({
    fromName: '',
    cuil: '',
    toEmail: '',
    toEmailAlt:''
  });


  const cambioCUE = (e) =>{
      setCUE(e.target.value)  
   } 

  const ProfileContent = () => {

    const { instance, accounts, inProgress } = useMsal();
    //setUser(accounts)

    //console.log('Bienvenido '+accounts[0].name)
    //console.log(accounts[0].username)

    //console.log(User)
    sessionStorage.setItem("usuario", JSON.stringify(accounts));
   
  };

  const restaurarClave = () => {
    setPASO1(false)
    setPASO2(true)
    setPASO3(true)
  }

  const seleccionEscuela = () =>{

    setPASO4(false)
    setPASO5(true)
  }

  const Cancelar = () => {

    setCUE('')
    setDatos([])

    setPASO1(true)
    setPASO2(false)
    setPASO3(false)
    setPASO4(false)
    setPASO5(false)
    setPASO6(false)

    setEmailData({
        fromName: '',
        cuil: '',
        toEmail: '',
        toEmailAlt:''
      })
    }     

  useEffect(() => {

    
    const fetchData = async () => {

      if(document.getElementById('input_CUE')){
        const SEARCH = document.getElementById('input_CUE');
        const valor_search = SEARCH.value.toUpperCase()

        if (CUE.trim().length >= 9) {

            if(valor_search === '' || valor_search.length > 9 || !(valor_search.search(14) == 0)){
    
                    if(valor_search.search("EE") == 0){
                        //console.log('es EE te digo e')

                        try {
                            const response = await fetch('https://escuelas.educacioncba.edu.ar/api/escuela/codigo-empresa/'+valor_search)  
                            const data = await response.json();
                            //console.log(data.data)
                            //setDatos(data.data);
                            //setEscuelaSeleccionada(datos.id)
                            
                        } catch (error) {
                            console.error('Error al obtener datos:', error);
                        }

                    }else{
                        SEARCH.setAttribute('placeholder', 'Ingrese CUE/EE');
                        //DIV_ERROR.innerHTML = 'El valor a buscar debe tener 9 digitos (140****** ó EE1111***)'
                        SEARCH.classList.add('is-invalid')
                        SEARCH.focus();
                        return
                    }
            }
        
            try {
                const response = await fetch('https://escuelas.educacioncba.edu.ar/api/escuela/cue/'+CUE)  
                const data = await response.json();
                //console.log(data.data)
                setDatos(data.data);

                setPASO3(false)
                setPASO4(true)                
           

            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        } else {

            //setDatos(null); // Si no hay búsqueda, establece datos a null
      }
    }
    };


    fetchData();

  }, [CUE]); 

  const isAuthenticated = useIsAuthenticated();


  const handleChange = (e) => {
    setEmailData({
        ...emailData,
        [e.target.id]: e.target.value,
    });
  };

  function getFormattedDate() {
    const now = new Date();

    // Obtener año, mes, día, hora, minutos y segundos
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Meses en JavaScript son 0-indexados
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    // Formatear la fecha en el formato deseado
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const Guardar = async() => {
    
    setPASO4(false)
    setPASO5(false)
    setPASO6(true)

    const ahora = getFormattedDate()

    try {
      const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: 
        JSON.stringify(
          {
            "fecha": ahora,
            "nombre": emailData.fromName,
            "cuil":emailData.cuil,
            "email":emailData.toEmail,
            "email_alt":emailData.toEmailAlt,
            "cue":CUE,
            "estado": 1,
            "detalle":''
        })
      
      
      
      };
      
      await fetch('https://mesadeayuda.pjb.edu.ar', options)
        .then(response => response.json())
        .then(response => console.log(response))
        .catch(err => console.error(err));

        setEmailData({
          fromName: '',
          cuil: '',
          toEmail: '',
          toEmailAlt:''
        })
          
      } catch (error) {
          console.error('Error al guardar los datos:', error);
      }
    };



  return (

   <PageLayout>
      <AuthenticatedTemplate>

      <ProfileContent />
            <div className="App">
            <BrowserRouter>
              <Routes>
                <Route path='/' element={<Principal />}/>
                <Route path='/asistencias/:id_escuela' element={<Asistencias/>} />
                <Route path='/nuevo' element={<CasoNuevo/>} />
                <Route path='/mostrar/:numero' element={<MostrarCaso/>} />
                <Route path='/template/:datos' element={<Template/>} />
                <Route path='/gestionBlanqueo/:id_caso' element={<GestionBlanqueo/>} />
                <Route path='*' element={<h1>No existe la página</h1>} />               
              </Routes>
            </BrowserRouter>
          </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
          <div className="container-fluid min-vh-100 d-flex flex-column ">
            <div className="row flex-grow-1 bg-primary ">

                <div className="col-md-5 border d-flex justify-content-center align-items-center  ">
                    
                    <div className={PASO1 == true ? 'col-10 mx-3' : 'd-none' } >
              
                        <h3 className='text-secondary mb-4'>Inicio de sesión</h3>          
                        <h5 className='text-secondary mb-3'><em>Ingresar con la cuenta institucional provista por el Ministerio de Educación de la Provincia de Córdoba</em></h5> 
                        <h6 className='mb-5'><em>@me.cba.gov.ar</em></h6>

                        { isAuthenticated ? <SignOutButton /> : <SignInButton /> }
                        
                         <br/><br/>
                        
                        <a className="mt-2" onClick={() => restaurarClave()}>He olvidado mi contraseña</a>

                    </div>
                    <div className={PASO2 == true ? 'col-10': 'd-none'} >

                        <h3 className='text-secondary mb-4'>Recuperar contraseña</h3>          
                        
                          <div className={PASO3 == true ?  'row' : 'd-none' } >
                                <h5 className='text-secondary mb-3'><em>Ingrese el CUE del establecimiento</em></h5> 
                                <div className='col-12 col-md-8'>
                                <div className="input-group mb-3 ">
                                    <input   
                                        aria-describedby="btn_buscarCUE"
                                        type="text" 
                                        id="input_CUE" 
                                        value={CUE}
                                        onChange={cambioCUE} 
                                        onInput={cambioCUE}
                                        minLength={8}
                                        className="form-control" 
                                        aria-label="Sizing example input" 
                                    
                                        ></input>
                                </div>
          
                                </div>

                          </div>

                          <div className={PASO4 == true ? 'row' : 'd-none'} >
                                <h5 className='text-secondary mb-3'><em>Seleccione el establecimiento al que pertenece</em></h5> 
                                <div className='col-12 '>
                                 
                                  {datos ? 
                               ( <div className="card-body">
  
                                        {datos.map((escuela) => (
                                           <a type="button" className='text-decoration-none' onClick={seleccionEscuela}>
                                                <div className="card text-secondary mt-2 mb-2">
                                                    <div className="card-body">
                                                        <h5 className="card-title">{escuela.cue.nombre} ({escuela.codigo_empresa})</h5>
                                                        <div>CUE: {escuela.cue.cue}</div>
                                                        <div>Nivel: {escuela.nivel.nombre}</div>
                                                    </div>
                                                </div>
                                                                                  
                                                </a>

                                        ))}
                                
                                </div>
                               )
                               :
                               (
                                <div className='col-12 text-center mt-2'>

                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <br/>
                                <p>Buscando</p>
                              </div> 
                                )
                              
                                
                            } 
                                   
                                </div>

                          </div>

                          <div className={PASO5 == true ? 'row' : 'd-none'} >
                                <h5 className='text-secondary'><em>Ingrese la información solicitada</em></h5> 

<div className='col-12 mt-4'>
            <div className="input-group">
                <input 
                    type="text" 
                    className="form-control" 
                    aria-label="Sizing example input" 
                    aria-describedby="inputGroup-sizing-default" 
                    value={emailData.toEmail}
                    onChange={handleChange}
                    id="toEmail"
                />
                <span className="input-group-text" id="inputGroup-sizing-default">@me.cba.gov.ar</span>
            </div>
            <div id="passwordHelpBlock" className="form-text mb-3">
                Ingrese el correo si lo conoce
            </div>

            <div className="card">
                <div className="card-header">
                    Responsable
                </div>
                <div className="card-body">
                    <label htmlFor="inputNombre5" className="form-label">Nombre/s y Apellido/s</label>
                    <input 
                        type="text" 
                        id="fromName" 
                        className="form-control mb-3" 
                        aria-describedby="NombreHelpBlock"
                        value={emailData.fromName} 
                        onChange={handleChange} 
                    />

                    <label htmlFor="cuil" className="form-label">CUIL</label>
                    <input 
                        type="text" 
                        id="cuil" 
                        className="form-control mb-3" 
                        aria-describedby="ApellidoHelpBlock"
                        value={emailData.cuil} 
                        onChange={handleChange}
                    />
                    
                    <label htmlFor="toEmailAlt" className="form-label">Correo electrónico alternativo</label>
                    <input 
                        type="email" 
                        id="toEmailAlt" 
                        className="form-control"  
                        aria-describedby="ApellidoHelpBlock"
                        value={emailData.toEmailAlt} 
                        onChange={handleChange}
                    />
                </div>
            </div>
          
        </div>
                              <div className="d-grid gap-2 d-flex justify-content-center mt-4">
                                   <button 
                                     onClick={Cancelar}
                                     className="btn btn-outline-danger" 
                                     type="button">
                                      Cancelar
                                   </button>

                                  <button 
                                    className="btn btn-outline-success" 
                                    type="button"
                                    onClick={Guardar}
                                    >
                                      Guardar
                                  </button>
                      
                                </div>
                          </div>


                          <div className={PASO6 == true ? 'card' : 'd-none'}>
                
                                <div className="card-body">
                                  <h5 className="card-title mt-3 text-center">Se ha generado el pedido con éxito!</h5>
                                  <p className="card-text text-center">Nos comunicaremos a la brevedad con usted.</p>
                                  <div className='d-flex justify-content-center'>
                                  <button  onClick={Cancelar} className="btn btn-outline-success " type="button">Volver</button>
                                  </div>
                                </div>
                              </div>
                    </div>


                </div>

                <div className="col-12 col-md-7 border bg-white d-flex justify-content-center align-items-center">
                        {/*<img 
                            src= {logo_ministerio} 
                            className="d-inline-block align-text-top login-logo-ministerio mx-2"
                        />*/}
                        <img 
                            src= {logo_pipal} 
                            className="d-inline-block align-text-top login-logo-subdireccion"
                        />
                </div>
            </div>
          </div>
      </UnauthenticatedTemplate>
    </PageLayout>

  );
}


