import React, { useEffect, useState } from 'react'

import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import { useParams } from 'react-router-dom'

import {NavAsistencia} from '../components/NavAsistencia'
import { Navbar } from '../components/Navbar'
import { Footer } from '../components/Footer'

import './MostrarCaso.css'

const URL_ASISTENCIAS = 'https://api-asistencias.educacioncba.edu.ar/'
const URL_CONTACTOS = 'https://api-contactos.educacioncba.edu.ar'



export const MostrarCaso = () => {

  const { numero } = useParams()
  const [caso, setCaso] = useState(null)
  const [escuela, setEscuela] = useState(null)




  const obtenerDatos = async () =>{

    
    const data = await fetch(URL_ASISTENCIAS+'/tickets?nro_ticket='+numero)
    const ticket = await data.json()

    setCaso(ticket.data)

    //console.log('')

    cargarEscuela(ticket.data[0].asistencia.id_escuela)
    cargarCaso(ticket.data)
  }

  const cargarCaso = async (ticket) =>{

    let gralCaso = document.getElementById('gralCaso')
    let detalleCaso = document.getElementById('detalleCaso')

    let lista = document.createElement('list')
    lista.setAttribute('class', 'list-group list-group-flush')
  
    ticket.forEach(tkt => {
  
      //console.log(tkt)

      let tktEstado = tkt.estado
      
      let contNroTicket = document.createElement('div')
      contNroTicket.setAttribute('class', 'd-flex flex-row')
     
      let hTitulo = document.createElement('h5')
      hTitulo.setAttribute('class', 'mt-3 mx-2')
      hTitulo.setAttribute('id', 'numeroCaso')
      hTitulo.innerHTML = tkt.nro_ticket  

      let estado = document.createElement('p')
      estado.setAttribute('class','rojo mt-3')
      estado.innerHTML = ' <div class="hstack gap-1"><span class="material-symbols-outlined">radio_button_checked</span>'+tktEstado[0].toUpperCase() + tktEstado.substring(1) +'</div>'

      contNroTicket.appendChild(hTitulo)
      contNroTicket.appendChild(estado)

      let nro_ticket = document.createElement('p')
      nro_ticket.setAttribute('class', 'mx-1 mb-1')
      nro_ticket.appendChild(contNroTicket)

      let titulo = document.createElement('p')
      titulo.setAttribute('class', 'mx-3 mb-2 ')
      titulo.innerHTML = tkt.titulo

      let servicio = document.createElement('p')
      servicio.setAttribute('class', 'mx-3 mb-1')
      servicio.innerHTML = '<strong> Servicio: </strong>'+tkt.servicio

      let analista = document.createElement('p')
      analista.setAttribute('class', 'mx-3 mb-1')
      analista.innerHTML = '<strong> Analista: </strong>' + tkt.nombre_analista

      let inicio = document.createElement('p')
      inicio.setAttribute('class', 'mx-3 mb-1')
      inicio.innerHTML = '<strong> Creación: </strong>' + tkt.fecha_creacion
   
      let solucion = document.createElement('p')
      solucion.setAttribute('class', 'mx-3 mb-1')
      solucion.innerHTML = '<strong> Solución: </strong>' + tkt.solucion

      let descripcion = document.createElement('p')
      descripcion.setAttribute('class', 'mx-3 mt-3 mb-3')
      descripcion.innerHTML =  tkt.descripcion

      let actual = document.createElement('p')
      actual.setAttribute('class', 'mb-1 mt-3 list-group-item-light bg-light d-flex justify-content-center')
      actual.innerHTML = '<strong class="mx-2"> Utima actualización: </strong>' + tkt.fecha_ult_act

      lista.appendChild(nro_ticket)
      lista.appendChild(titulo)
      
      lista.appendChild(analista)
      lista.appendChild(servicio)
      lista.appendChild(inicio)
      lista.appendChild(solucion)
      lista.appendChild(descripcion)
      lista.appendChild(actual)

      cargarBitacora(tkt.bit_publica.entries)
       
    });

    gralCaso.appendChild(lista)

  }
  
  const cargarEscuela = async (id_escuela) =>{

    const data = await fetch('https://escuelas.educacioncba.edu.ar/api/escuela/id/'+id_escuela)
    const escuela = await data.json()

    setEscuela(escuela)

    //console.log('escuela')

    let infoEscuela = document.getElementById('infoEscuela')
    
    let lista = document.createElement('list')
    lista.setAttribute('class', 'list-group list-group-flush')
  

    escuela.data.forEach(establecimiento => {
  
      //console.log(establecimiento)

      let nombre = establecimiento.cue.nombre
      
      let contNombre = document.createElement('div')
      contNombre.setAttribute('class', 'd-flex flex-row')
     
      let hNombre = document.createElement('h5')
      hNombre.setAttribute('class', 'mx-2 mt-3')
      hNombre.innerHTML = nombre
  
      contNombre.appendChild(hNombre)

      let cue = document.createElement('p')
      cue.setAttribute('class', 'mx-3 mb-2 mt-3')
      cue.innerHTML = '<strong> CUE: </strong>'+establecimiento.cue.cue +' <strong class="mx-2"> EE: </strong>'+establecimiento.codigo_empresa

      let nivel = document.createElement('p')
      nivel.setAttribute('class', 'mx-3 mb-2')
      nivel.innerHTML = '<strong> Nivel: </strong>'+establecimiento.nivel.nombre

      let direccion = document.createElement('p')
      direccion.setAttribute('class', 'mx-3 mb-2')
      direccion.innerHTML = '<strong> Direccion: </strong>'+establecimiento.ubicacion.direccion +' - '+ establecimiento.ubicacion.localidad.nombre +' - '+ establecimiento.ubicacion.departamento.nombre

      let contMapa = document.createElement('p')
      contMapa.setAttribute('class', 'mt-4 mb-4 d-flex justify-content-center')

      let mapa = document.createElement('iframe')
      mapa.setAttribute('src', 'https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d5124.901502041544!2d'+establecimiento.ubicacion.longitud+'!3d'+establecimiento.ubicacion.latitud+'!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!4v1671456867731!5m2!1ses-419!2sar')
      mapa.setAttribute('referrerpolicy','no-referrer-when-downgrade')
      mapa.setAttribute('loading', 'lazy' )
      mapa.setAttribute('width', '400')
      mapa.setAttribute('height', '300')

      contMapa.appendChild(mapa)

      lista.appendChild(contNombre)
      lista.appendChild(cue)
      lista.appendChild(nivel)
      lista.appendChild(direccion)
      lista.appendChild(contMapa)

      
    });

    infoEscuela.appendChild(lista)

  }

const cargarBitacora = (bitacora) => {

  //console.log(bitacora)

  let contBitacora = document.getElementById('contBitacora')

  //console.log(bitacora)

  let array = bitacora.reverse()
  
  array.forEach(el => {

    //console.log(el)

    let card = document.createElement('div')
    card.setAttribute('class', 'card shadow mb-2 bg-white rounded')

    let cabecera = document.createElement('div')
    cabecera.setAttribute('class', 'card-header d-flex justify-content-between')

    let sms = el.message 
    let smsNombre

    if(sms.includes("[")){
      smsNombre = sms.split('[').pop().split(']')[0]
    }else{
      smsNombre = "-"
    }
    



  
    let nombre = document.createElement('strong')
    nombre.innerHTML = el.user_login + " ("+smsNombre+")"

    let fecha = document.createElement('small')
    fecha.innerHTML = el.date

    cabecera.appendChild(nombre)
    cabecera.appendChild(fecha)

    let cuerpo = document.createElement('div')
    cuerpo.setAttribute('class', 'card-body')
    
    let mensaje = document.createElement('p')
    mensaje.setAttribute('class', 'card-text')
    mensaje.innerHTML = sms.replace("["+smsNombre+"]", '')



    cuerpo.appendChild(mensaje)

    card.appendChild(cabecera)
    card.appendChild(cuerpo)

    contBitacora.appendChild(card)
    
  
  });

}

const guardarBitacora = async () => {
    
    let numeroCaso = document.getElementById('numeroCaso')
    let mensaje = document.getElementById('textoBitacora')
    let usuario =  JSON.parse(sessionStorage.getItem("usuario"));

    //username --> mail
    //name     --> nombre
  
    //console.log(numeroCaso.innerHTML) 
    //console.log( mensaje.value + ' ['+usuario[0].name+']') 

    let nroticket = numeroCaso.innerHTML
    let msj =  mensaje.value + ' ['+usuario[0].name+']'

    const options = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(
         {
          nro_ticket: nroticket,
          bit_publica: msj
         }),
    };
    
    mensaje.value = ''
    
    //al obtener bitacora mostrarla automatica... var react

    await fetch(URL_ASISTENCIAS +'tickets', options)
      .then(response => response.json())
      .then(response => console.log(response))
      .catch(err => console.error(err));

      window.location.reload(true);    
}



  useEffect(() => {
    obtenerDatos()
  }, [])

  return (
    <>
      <Navbar/>
      <div id="contGeneral" className="container">
            <NavAsistencia/>
            <div className="card mt-4">
                <div className="card-header">
                <div className="hstack gap-1">
                <span className="material-symbols-outlined lg">
                  assignment
                </span>
                  Asistencia
                  </div>
                </div>
                <div className="card-body">
                    <div className='row'>

                    <div className='col-12 col-lg-6 mt-2'>
                        <div className="card">
                                <div className="card-header bg-transparent">
                                  <div className="hstack gap-1"> 
                                  <span className="material-symbols-outlined celeste">
                                    assignment_late
                                  </span>
                                        General
                                  </div>      
                                </div>
                                <div className="card-body">
                                    { !caso ? 
                                      <div className="text-center">
                                        <h6 className="text-secondary mt-3">Cargando información...</h6>
                                        <div className="spinner-border text-secondary" role="status">
                                              <span className="visually-hidden">Loading...</span>
                                        </div> 
                                      </div>  
                                      : ''
 
                                    }  

                                    <div id="gralCaso"> 

                                    </div>     
                                </div>
                            </div>                          
                        </div>

                        <div className='col-12 col-lg-6 mt-2'>
                            <div className="card">
                                <div className="card-header bg-transparent">
                                  <div className="hstack gap-1">
                                      <span className="material-symbols-outlined celeste">
                                        school
                                      </span>
                                      Escuela
                                  </div>   
                                </div>
                                <div className="card-body">
                                    { !escuela ? 
                                          <div className="text-center">
                                            <h6 className="text-secondary mt-3">Cargando información...</h6>
                                            <div className="spinner-border text-secondary" role="status">
                                                  <span className="visually-hidden">Loading...</span>
                                            </div> 
                                          </div>  
                                          : ''
    
                                    }  
                                  <div id="infoEscuela"> 

                                  </div>  
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-12'>
                        <div className="card mt-3">
                                <div className="card-header bg-transparent">
                                <div className="hstack gap-1"> 
                                  <span className="material-symbols-outlined celeste">
                                  view_timeline
                                  </span>
                                    Historial de gestión
                                </div>  
                                </div>
                                <div className="card-body">

                                    { !caso ? 
                                          <div className="text-center">
                                            <h6 className="text-secondary mt-3">Cargando información...</h6>
                                            <div className="spinner-border text-secondary" role="status">
                                                  <span className="visually-hidden">Loading...</span>
                                            </div> 
                                          </div>  
                                          : ''
 
                                    }  
                                    <div id="detalleCaso" > 

                                    </div>  
                                    <div className='row mt-3 d-flex justify-content-center'>
                                       <div className='col-12 col-md-10 mb-3'>
                                          <div className="card ">
                                              <div className="card-header bg-transparent">
                                                <div className="hstack gap-1"> 
                                                <span className="material-symbols-outlined celeste">
                                                  chat_add_on
                                                </span>
                                                      Nueva intervención
                                                </div>      
                                              </div>
                                              <div className="card-body">                       
                                                  <div className="form-floating">
                                                    <textarea className="form-control" placeholder="Leave a comment here" id="textoBitacora" ></textarea>
                                                    <label htmlFor="floatingTextarea2">Comentario</label>
                                                  </div>

                                                  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                     <button type="button" className="btn btn-outline-secondary mt-3" onClick={guardarBitacora}>Guardar</button>
                                                  </div>
                                                  

                                              </div>
                                          </div>   
                                        </div>
                                        <div id="contBitacora" className='col-12 col-md-10'>

                                         </div>
                                      </div>
                                </div>
                            </div>
                        </div>
                    </div>

                 
                </div>
            </div>
          </div>
      <Footer/>
    </>  
  )
}