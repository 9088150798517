import React, { useEffect, useState } from 'react'
import { useParams} from "react-router-dom";
import {Navbar} from '../components/Navbar'
import {Footer} from '../components/Footer'
import {NavAsistencia} from '../components/NavAsistencia'

export function GestionBlanqueo() {

    const { id_caso } = useParams()
    const [casoBlanqueo, setCasoBlanqueo] = useState({});
    const [escuela, setEscuela] = useState(null)
    const [detalle, setDetalle] = useState('')
    const [estadoBlanqueo, setEstadoBlanqueo] = useState(false)
    
    useEffect(() => {
        obtenerCaso(id_caso)
    }, [])
  
    const handleDetalle = (e) =>{
      setDetalle(e.target.value)
    }

    const obtenerCaso = async() =>{
      
      await fetch('https://mesadeayuda.pjb.edu.ar/?id='+id_caso)
      //await fetch('http://localhost/api/blanqueos/?id='+id_caso)
        .then(response => response.json())
        .then(response => 
        {
            console.log(response[0]) 
            setCasoBlanqueo(response[0])
            cargarEscuela(response[0].cue)
            verificarEstado(response[0].estado)
        }
        
        )
        .catch(err => console.error(err)); 
    }

    const verificarEstado = (estado) =>{

          console.log(estado)

         if(parseInt(estado) === 2){
            setEstadoBlanqueo(true)
          }
    }
    const cargarEscuela = async (cue_escuela) =>{

        const data = await fetch('https://escuelas.educacioncba.edu.ar/api/escuela/cue/'+cue_escuela)
        const escuela = await data.json()
    
        setEscuela(escuela)
    
        //console.log('escuela')
    
        let infoEscuela = document.getElementById('infoEscuela')
        
        let lista = document.createElement('list')
        lista.setAttribute('class', 'list-group list-group-flush')
      
    
        escuela.data.forEach(establecimiento => {
      
          //console.log(establecimiento)
    
          let nombre = establecimiento.cue.nombre
          
          let contNombre = document.createElement('div')
          contNombre.setAttribute('class', 'd-flex flex-row')
         
          let hNombre = document.createElement('h5')
          hNombre.setAttribute('class', 'mx-2 mt-3')
          hNombre.innerHTML = nombre
      
          contNombre.appendChild(hNombre)
    
          let cue = document.createElement('p')
          cue.setAttribute('class', 'mx-3 mb-2 mt-3')
          cue.innerHTML = '<strong> CUE: </strong>'+establecimiento.cue.cue +' <strong class="mx-2"> EE: </strong>'+establecimiento.codigo_empresa
    
          let nivel = document.createElement('p')
          nivel.setAttribute('class', 'mx-3 mb-2')
          nivel.innerHTML = '<strong> Nivel: </strong>'+establecimiento.nivel.nombre
    
          let direccion = document.createElement('p')
          direccion.setAttribute('class', 'mx-3 mb-2')
          direccion.innerHTML = '<strong> Direccion: </strong>'+establecimiento.ubicacion.direccion +' - '+ establecimiento.ubicacion.localidad.nombre +' - '+ establecimiento.ubicacion.departamento.nombre
    
          let contMapa = document.createElement('p')
          contMapa.setAttribute('class', 'mt-4 mb-4 d-flex justify-content-center')
    
          let mapa = document.createElement('iframe')
          mapa.setAttribute('src', 'https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d5124.901502041544!2d'+establecimiento.ubicacion.longitud+'!3d'+establecimiento.ubicacion.latitud+'!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!4v1671456867731!5m2!1ses-419!2sar')
          mapa.setAttribute('referrerpolicy','no-referrer-when-downgrade')
          mapa.setAttribute('loading', 'lazy' )
          mapa.setAttribute('width', '400')
          mapa.setAttribute('height', '300')
    
          contMapa.appendChild(mapa)
    
          lista.appendChild(contNombre)
          lista.appendChild(cue)
          lista.appendChild(nivel)
          lista.appendChild(direccion)
          lista.appendChild(contMapa)
    
          
        });
    
        infoEscuela.appendChild(lista)
    
      }

    const guardarGestion = async() =>{
      
      console.log(id_caso)
      console.log(detalle)
      let estado = 2

      const options = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: '{"id":"'+id_caso+'","estado":'+parseInt(estado)+',"detalle":"'+detalle+'"}'
        
      };
      
    //await fetch('http://localhost/api/blanqueos/', options)
    await fetch('https://mesadeayuda.pjb.edu.ar/', options)
        .then(response => response.json())
        .then(response => {
          console.log(response)
          if(response.status == "success"){
            setEstadoBlanqueo(true)  
            obtenerCaso()
          }
        })
        .catch(err => console.error(err));
 
    }  

    
    return (
        <div>
            <Navbar/>
          <div className="container">
              <div className='row'>
                <div className='col-12 col-lg-6 mt-2'>
                            <div className="card">
                                <div className="card-header bg-transparent">
                                  <div className="hstack gap-1">
                                      <span className="material-symbols-outlined celeste">
                                        school
                                      </span>
                                      General 
                                  </div>   
                                </div> 
                                <div className="card-body">
                                { !casoBlanqueo ? 
                                          <div className="text-center">
                                            <h6 className="text-secondary mt-3">Cargando información...</h6>
                                            <div className="spinner-border text-secondary" role="status">
                                                  <span className="visually-hidden">Loading...</span>
                                            </div> 
                                          </div>  
                                          : 

                                          <div>
                                              <ul className='list-group list-group-flush'>
                                                <li class="list-group-item"><b>Nombre/s y apellido/s:</b> {casoBlanqueo.nombre}</li>
                                                <li class="list-group-item"><b>CUIL: </b>{casoBlanqueo.cuil}</li>
                                                <li class="list-group-item">{casoBlanqueo.email}<b>@me.cba.gov.ar</b></li>
                                                <li class="list-group-item"><b>Correo alternativo: </b>{casoBlanqueo.emailAlt}</li>
                                                <li class="list-group-item"><b>Fecha de creación:</b> {casoBlanqueo.fecha}</li>
                                              </ul>
                                          </div>

                                }  

                                       
              
                                </div>   
                                <div className= {estadoBlanqueo === false ? '' : 'd-none' } >
                                  <div className='row'>
                                    <div className='col-10 mx-4'>
                                      <b className='mx-2'>Gestión</b>
                                      <div class="input-group mt-2 mx-2">
                                          <textarea 
                                          class="form-control" 
                                          aria-label="With textarea"
                                          onChange={handleDetalle}
                                          ></textarea>
                                      </div>
                                      
                                      </div>
                                      <div className='col-10 mb-3 mt-3 d-flex justify-content-end'>
                                        <button 
                                                type="button" 
                                                className= "btn btn-outline-secondary" 
                                                onClick={guardarGestion}>
                                                Aceptar
                                            </button>
                                        
                                      </div>
                                    </div>
                                </div>

                                <div className= {estadoBlanqueo === false ? 'd-none' : ''} >                                       
                                  <div>
                                      <ul className='list-group list-group-flush mx-3'>
                                        <li class="list-group-item"><b>Estado:</b> {casoBlanqueo.estado == 0 || casoBlanqueo.estado == 1 ? 'Abierto' : "Cerrado"}</li>
                                        <li class="list-group-item"><b>Detalle de gestión: </b>{casoBlanqueo.detalle}</li>

                                      </ul>
                                  </div>
                                </div>
                            </div>
                </div>
                <div className='col-12 col-lg-6 mt-2'>
                            <div className="card">
                                <div className="card-header bg-transparent">
                                  <div className="hstack gap-1">
                                      <span className="material-symbols-outlined celeste">
                                        school
                                      </span>
                                      Escuela
                                  </div>   
                                </div>
                                <div className="card-body">
                                    { !escuela ? 
                                          <div className="text-center">
                                            <h6 className="text-secondary mt-3">Cargando información...</h6>
                                            <div className="spinner-border text-secondary" role="status">
                                                  <span className="visually-hidden">Loading...</span>
                                            </div> 
                                          </div>  
                                          : ''
    
                                    }  
                                  <div id="infoEscuela"> 

                                  </div>  
                                </div>
                            </div>
                        </div>

             </div>
          </div>
            
            <Footer/>
        </div>
    )
}


