import React from "react";
import Navbar from "react-bootstrap/Navbar";

import { useIsAuthenticated } from "@azure/msal-react";
import { SignOutButton } from "./SignOutButton";


export const PageLayout = (props) => {

    const isAuthenticated = useIsAuthenticated();
    
    return (
        <>
            <Navbar className="navegador" >
                <div>
                    <form>
                    { isAuthenticated ? <SignOutButton /> : '' }
                    </form>
                </div>
            </Navbar>
           
            {props.children}
        </>
    );
};