import React from 'react'
import {Navbar} from '../components/Navbar'
import {Footer} from '../components/Footer'
import {ListarCasos} from '../components/ListarCasos'
import {NavAsistencia} from '../components/NavAsistencia'

import { useParams} from "react-router-dom";


export const Asistencias = (escuelaSeleccionada) => {
  
  const { id_escuela } = useParams()
  let idEscuela 

 if(id_escuela){
    idEscuela = sessionStorage.setItem('idEscuela', id_escuela)
 }else{
     idEscuela = sessionStorage.getItem('idEscuela')
 }


  return (
    <>
      <Navbar />
      <div className="container">
    
          <NavAsistencia/>
          <ListarCasos/>
        
      </div>
      <Footer />
    </>
  )
}
