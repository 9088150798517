import React, { useState, useEffect } from 'react';
import { Link, seParams, useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';



import './Principal.css'

import {Navbar} from '../components/Navbar'
import {Footer} from '../components/Footer'


//140333800 carbo
//user[0].name = 'EE0310628';

export function Principal() {
  
    const user =  JSON.parse(sessionStorage.getItem('usuario'));  

    const [CUE, setCUE] = useState('');
    const [ESTADO, setEstado] = useState(false);
    const [datos, setDatos] = useState([]);
    const [permiso, setPermiso] = useState('');
    const [usuariosBlanqueos, setUsuariosBlanqueos] = useState([]);

    const correosAutorizados = [
     "gustavo.villarreal@me.cba.gov.ar",
     "hernan.monzon@me.cba.gov.ar",
     "bernardo.gil@me.cba.gov.ar",
     "rodrigo.maffey@me-cba.gov.ar",
     "eugenio.regis@me.cba.gov.ar",
     "alejandra.lopez@me.cba.gov.ar",
     "marcelo.perezunamuno@me.cba.gov.ar", 
     "ivan.faure@me.cba.gov.ar",
    ];

    useEffect(() => {
        if (correosAutorizados.includes(user[0].username)) {

          setPermiso('equipoMesa')  
          obtenerPedidosBlanqueo();

        }else{

            setPermiso('usuario')
        }
    }, []);

    const colums = [
        {
         name: 'DETALLE',
         right: true,   
         maxWidth: '2px',
      
         cell: (row) => (
            <Link className="btn btn-outline-secondary rounded-pill border border-0" to={'/gestionBlanqueo/'+ row.id }> 
                <span className="material-symbols-outlined align-middle ">
                  visibility
                </span>
            </Link>
   ),

        },
        {
          name: 'CUE',
          selector: row => row.cue,
          center:true,
        },
        {
          name: 'Nombre/Apellido',
          selector: row => row.nombre,
          style: {textTransform: 'capitalize'},
          sortable: true,
          left: true,
        },
        {
          name: 'CUIL',
          selector: row => row.cuil,
          sortable: true,
          left: true,
             
      
        },
           
        {
          name: 'FECHA CREACIÓN',
          selector: row => row.fecha,
          sortable: true,
          left: true,
        },
        {
            name: 'ESTADO',
            selector: row => row.estado,
            sortable: true,
            left: true,
            cell: row => (
                <div
                    style={{
                        color: parseInt(row.estado) === 1 ?  '#C0CD29' : '#D37E32', // Color basado en el valor del estado
                        textTransform: 'capitalize',
                        cursor: 'pointer',
                    }}
                >
                    {parseInt(row.estado) === 1 ? 'Abierto' : 'Cerrado' }
                </div>
            ),
        },
      ]

    
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
      };
        
    const obtenerPedidosBlanqueo = async() =>{

        await fetch('https://mesadeayuda.pjb.edu.ar/') 
        //await fetch('http://localhost/api/blanqueos')  
        .then(response => response.json())
        .then(response => setUsuariosBlanqueos(response))
        .catch(err => console.error(err));

    } 

    const cambioCUE = (e) =>{
        setCUE(e.target.value)  
    } 

    //user[0].name = 'EE0310628'    
    const esColegio = (user) => { 
        let estate;

        if(user[0].name.includes('EE')){
            estate = true    
        }else{
            estate = false
        }
        return estate
    
    }
    
    const resultadoCondicion = esColegio(user);

     useEffect(() => {

    setEstado(resultadoCondicion);

    const fetchData = async () => {

        const SEARCH = document.getElementById('input_CUE');
        const RBusqueda = document.getElementById('resultadoBusqueda')
        const valor_search = SEARCH.value.toUpperCase()

        RBusqueda.classList.remove('d-none') 

        if (RBusqueda) {
            RBusqueda.scrollIntoView({ behavior: 'smooth' });
        }
    


        if (CUE.trim().length >= 9) {

            if(valor_search === '' || valor_search.length > 9 || !(valor_search.search(14) == 0)){
    
                    if(valor_search.search("EE") == 0){
                        //console.log('es EE te digo e')

                        try {
                            const response = await fetch('https://escuelas.educacioncba.edu.ar/api/escuela/codigo-empresa/'+valor_search)  
                            const data = await response.json();
                            //console.log(data.data)
                            setDatos(data.data);
                            //setEscuelaSeleccionada(datos.id)
                            
                        } catch (error) {
                            console.error('Error al obtener datos:', error);
                        }

                    }else{
                        SEARCH.setAttribute('placeholder', 'Ingrese CUE/EE');
                        //DIV_ERROR.innerHTML = 'El valor a buscar debe tener 9 digitos (140****** ó EE1111***)'
                        SEARCH.classList.add('is-invalid')
                        SEARCH.focus();
                        return
                    }
            }
        
            try {
                const response = await fetch('https://escuelas.educacioncba.edu.ar/api/escuela/cue/'+CUE)  
                const data = await response.json();
                //console.log(data.data)
                setDatos(data.data);
                //setEscuelaSeleccionada(datos.id)

            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        } else {
            RBusqueda.classList.add('d-none') 
            setDatos(null); // Si no hay búsqueda, establece datos a null
      }
    };

    fetchData();

     }, [CUE]); 

  return (
    <div>
        
    <Navbar />

    <div className='container mt-4'>
       <div className="row  d-flex justify-content-center">
            <div className="col-12">
                <div className="card text-secondary">
                <div className="card-header">
                    <div className="hstack gap-1">
                        <span className="material-symbols-outlined">
                            home
                        </span>
                        <span>
                            Inicio
                        </span>
                    </div>
                </div>



                <div className="card-body">

                        <div className="card text-secondary">
               
                            <div className="card-body">

                                <h5 className="card-title d-flex aling-items-center"><span className="material-symbols-outlined mx-1">account_circle</span> {user[0].name} </h5>
                                {resultadoCondicion == false ?
                                  (<p className="card-text mx-2">
                                     Cuenta <strong>Personal</strong> provista por el Ministerio de Educación
                                  </p> )
                                  :
                                  (<p className="card-text mx-2">
                                    Cuenta <strong>Institucional</strong> provista por el Ministerio de Educación
                                  </p>)

                                } 
                          
                            </div>
                        </div>
                  
                

                        <div className='col-12 d-flex justify-content-center mt-4 mb-4'> 
                             <iframe 
                                    width="360" 
                                    height="215" 
                                    src="https://www.youtube.com/embed/DFaY6eAQLI0?si=izBemErFbPchIZRq" 
                                    title="Video explicativo de como cargar arranques en 2 minutos en la netbook de provinica" 
                                    frameborder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                    referrerpolicy="strict-origin-when-cross-origin" 
                                    allowfullscreen
                                    className= {permiso === 'usuario' ? '' : 'd-none'}
                                ></iframe>                                                             
                    
                        </div> 

                        <div className='col-12 mt-4 '>
                               <div className={permiso === 'equipoMesa'? '' : 'd-none'}>
                                    <DataTable
                                        columns = {colums}
                                        data = {usuariosBlanqueos}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                        defaultSortFieldId={1}
                                
                                    />
                                 </div>
                         </div>
                        <div className='col-12 mt-4 d-flex justify-content-center'>
                            <span className="material-symbols-outlined text-warning">
                                info
                            </span>
                            <p className='text-warning'>
                               Ingrese un CUE o EE válido del cual desea gestionar una asistencia
                            </p> 
                            </div>
                        <div className='row d-flex justify-content-center '>

                            <div className='col-12 col-md-5 '>
                            <div className="input-group mb-3 ">
                                <input aria-describedby="btn_buscarCUE"
                                
                                    type="text" 
                                    id="input_CUE" 
                                    value={CUE}
                                    onChange={cambioCUE} 
                                    onInput={cambioCUE}
                                    minLength={8}
                                    className="form-control" 
                                    aria-label="Sizing example input" 
                                
                                    ></input>
                                
                                <button type="button" 
                                    className="btn btn-outline-secondary"
                                    id= "btn_buscarCUE"                                                                      
                                >
                                Buscar
                            </button>
                            </div>
                            </div>
                        </div>

               
                        <div className='col-12 d-none' id="resultadoBusqueda">
                            <div className="card">
                                <div className="card-header">
                                    Establecimiento
                                </div>
                             {datos ? 
                               ( <div className="card-body">
                                        <div className='col-12 mt-4 d-flex justify-content-start'>
                                            <span className="material-symbols-outlined text-warning mx-1">
                                                info
                                            </span>
                                            <p className='text-warning'>
                                            Seleccione la opción que corresponde a su establecimiento 
                                            </p>
                                        </div>   
                                        {datos.map((escuela) => (
                                       
                                            <Link to={"/asistencias/"+escuela.id} key={escuela.id}>
                                                <div className="card text-secondary mt-2 mb-2" >
                                                    <div className="card-body">
                                                        <h5 className="card-title">{escuela.cue.nombre} ({escuela.codigo_empresa})</h5>
                                                        <div>CUE: {escuela.cue.cue}</div>
                                                        <div>Nivel: {escuela.nivel.nombre}</div>
                                                    </div>
                                                </div>
                                            </Link>                                            
                                   

                                        ))}
                                
                                </div>
                               )
                               :
                               (
                                <div className='col-12 text-center mt-2'>

                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <br/>
                                <p>Buscando</p>
                              </div> 
                                )
                              
                                
                            }

                            </div>
                        </div>
                  

              
                    

                  
              
               
               </div>
                

                </div>
            </div>
        </div>    
    </div>
    <Footer/>  
</div>
)
}
