import React from 'react'
import logo_pipal from '../assets/img/logo_pipal.png';

import { NavLink } from 'react-router-dom';


export const Navbar = () => {



  return (
    <nav className="navbar navbar-light" Style="background-color: #edf0f1;">
     <div className="container-fluid ">  
        <div className="col-12 d-flex justify-content-left align-items-center">
            <NavLink to='/'>
                    <img 
                        src= {logo_pipal} 
                        className="d-inline-block align-text-top brand-logo mt-2"
                    />
            </NavLink>
        </div> 
      </div>
    </nav>
  )
}
