import { Fragment } from 'react'

export const Template = (data) => {

    console.log(data)

 return(
        <Fragment> {`

            <table style = "width: auto;" >
                <tr>
                    <td >
                        <strong>DATOS DE LA INSTITUCIÓN</strong>   
                        <ul style="list-style-type: none; margin-top:10px;" >
                            <li ><strong>CUE: </strong> `+data.cue+`</li>
                            <li ><strong>NOMBRE: </strong>`+data.nombreEscuela+`</li>
                            <li ><strong>NIVEL: </strong>`+data.nivelEscuela+`</li>
                            <li ><strong>LOCALIDAD: </strong>`+data.localidadEscuela+`</li>
                            <li ><strong>DIRECCIÓN: </strong>`+data.direccionEscuela+`</li>
                            <li ><strong>EMAIL: </strong>`+data.mailEscuela+`</li>
                            <li ><strong>TELEFONO ESCUELA: </strong>`+data.telContacto+`</li>  
                            <br>    
                            <li ><strong>PERSONA DE CONTACTO: </strong>`+data.nombreContacto+`</li>
                            <li ><strong>CARGO DE CONTACTO: </strong>`+data.cargoContacto+`</li>
                            <li ><strong>TELEFONO CONTACTO: </strong>`+data.celContacto+`</li>    
                            <li ><strong>EMAIL ALTERNATIVO: </strong>`+data.mailAltEscuela+`</li>                            
                        </ul>
                    </td>
                     </tr>
                      <tr>
                        <td>
                        <strong>DETALLE:</strong>   
                         <p style="margin-top:10px;">`+data.textCorreo+`</p>        
                        </td>
                      </tr>
                     <tr >
                      <td>
                          <strong>Reportado por:  </strong>`+data.usuario+`
                          
                     </td>
                      </tr>
             
                </table>
               
           

            `}
        </Fragment>
    )
}
