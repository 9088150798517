import React from 'react'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom'

export const NavAsistencia = () => {

   
    let idEscuela = sessionStorage.getItem('idEscuela')

  return (


    <div className="card mt-4">
    <div className="card-header">
        <div className="row">
            <div className="col-12 col-lg-12">
            
            </div>
        </div>
    </div>


<div className="card-body hstack gap-2" >

{/*
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
        <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" onClick={ console.log(window.location.origin+'/')}
        />
        <label class="btn btn-outline-primary" for="btnradio1">Radio 1</label>

        <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"/>
        <label class="btn btn-outline-primary" for="btnradio2">Radio 2</label>

        <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off"/>
        <label class="btn btn-outline-primary" for="btnradio3">Radio 3</label>
        </div>

*/}

    <span className="d-inline-block" tabIndex="0" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content="Inicio">
        <Link className="btn btn-outline-secondary  " to='/'>
            <div className="hstack gap-1">
                <span className="material-symbols-outlined">
                    home
                </span>
                <span>
                   Inicio
                </span>
            </div>
        </Link>
    </span>
    
    <Link className="btn btn-outline-secondary  " to={"/asistencias/"+idEscuela }>
    <div className="hstack gap-1">
        <span className="material-symbols-outlined">
                toc
        </span>
            <span>
                Historial 
            </span>
        </div>
    </Link>

    <Link className="btn btn-outline-secondary  " to='/nuevo'>
  

        
        <div className="hstack gap-1">
            <span className="material-symbols-outlined">
                add_circle
            </span>                   
            <span>
               Nuevo 
            </span>
        </div>
      
    </Link>

</div>
</div>


  )


}