import React from 'react'
import logo_footer from '../assets/img/logo_footer.png';

export const Footer = () => {
  return (
    <nav className="sticky-bottom mt-5 mb-1">
    <div id="contFooter" className="row" >
       
        <div className="navbar navbar-light text-muted mt-auto " >
           <div className="col-12 d-flex  justify-content-center">
                <img 
             className="banner mt-3" 
                src={ logo_footer } 
                alt="Ministerio banner"
      
                />
            </div> 
            <div className="col-12 col-md-12 d-flex justify-content-center mt-2">
                Contacto: soporte.escuelas@me.cba.gov.ar
            </div>
            <div className="col-12 col-md-12 text-center">
            © 2024 Copyright Ministerio de Educación de la Província de Córdoba- Subdirección de Infraestructura Tecnológica y Conectividad
            </div>        
        </div>
    </div>  
    </nav>
  )
}
