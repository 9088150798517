import React, {useState, useEffect} from 'react'
import { Link, useParams,useNavigate} from "react-router-dom";


import DataTable from 'react-data-table-component'


import 'styled-components'

export const ListarCasos = ({escuelaSeleccionada}) => {

const [casos, setCasos] = useState(null)
const { id_escuela } = useParams()

const URL_ASISTENCIAS = 'https://api-asistencias.educacioncba.edu.ar/'
const URL_CONTACTOS = 'https://api-contactos.educacioncba.edu.ar'

const navigate = useNavigate();

const obtenerDatos = async () =>{

    if(id_escuela === 'null'){

      navigate('/');
      return null;

    }

  const data = await fetch(URL_ASISTENCIAS+'tickets?id_escuela='+id_escuela) //1747  
  const ticket = await data.json()
  
  //console.log(ticket.data)

  ticket.data == undefined ? setCasos('nada') :  setCasos(ticket.data)

 
}

useEffect(() => {
  obtenerDatos()
}, [])

const colums = [
  {
    cell: (row) => (
            <Link className="btn btn-outline-secondary rounded-pill border border-0" to={'/mostrar/'+ row.nro_ticket }> 
                <span className="material-symbols-outlined align-middle ">
                  visibility
                </span>
            </Link>
   ),
   name: 'DETALLE',
   right: true,   
   maxWidth: '2px',

  },
  {
    name: 'N° CASO',
    selector: row => row.nro_ticket,
    center:true,
  },
  {
    name: 'SERVICIO',
    selector: row => row.asistencia.categoria.descripcion,
    style: {textTransform: 'capitalize'},
    sortable: true,
    left: true,
  },
  {
    name: 'ESTADO',
    selector: (row) => (   
        <div className='hstack gap-1'>
          <span className="material-symbols-outlined">
          radio_button_checked
          </span>
          { row.estado } 
        </div>
    ),
    sortable: true,
    left: true,
		conditionalCellStyles: [

      {
				when: row => row.estado == 'abierto',
				style: {
					color: '#E9BE3E',
          textTransform: 'capitalize',
					'&:hover': {
						cursor: 'pointer',
					},
				},
      },
      {
				when: row => row.estado == 'nuevo',
				style: {
					color: '#E9BE3E',
          textTransform: 'capitalize',
					'&:hover': {
						cursor: 'pointer',
					},
				},
      },
			{
				when: row => row.estado == 'asignado',
				style: {
					color: '#C0CD29',
          textTransform: 'capitalize',
					'&:hover': {
						cursor: 'pointer',
					},
				},
      },
      {
        when: row => row.estado == 'resuelto',
				style: {
					color: '#E9BE3E', //naranja
          textTransform: 'capitalize',
					'&:hover': {
						cursor: 'pointer',
					},
				},
			},
      {
        when: row => row.estado == 'cerrado',
				style: {

          textTransform: 'capitalize',
					color: '#6c757d', //celeste
					'&:hover': {
						cursor: 'pointer',
					},
				},
      }
    ]

  },

/*
#C0CD29  --> VERDE
#C5484C  --> ROJO
#E9BE3E  --> AMARILLO 
#D37E32  --> NARANJA
*/

  {
    name: 'FECHA CREACIÓN',
    selector: row => row.fecha_creacion,
    sortable: true,
    left: true,
  },

]

const paginationComponentOptions = {
  rowsPerPageText: 'Filas por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'Todos',
};


return (
    <div>

        <div id="contCaso" className="d-none">
        <div className="card mt-4">
                <div className="card-header">
                    <div className="row">
                        <div className="col-12 col-lg-12">
                        <div className="hstack gap-1">
                                <span className="material-symbols-outlined lg">
                                    terminal
                                </span>
                                <span>
                                    Asistencia
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div id="contLista">
            <div className="card mt-4">
                <div className="card-header">
                    <div className="row">
                        <div className="col-12 col-lg-12">
                        <div className="hstack gap-1">
                            <span className="material-symbols-outlined lg">
                                    toc
                            </span>
                                <span>
                                    Historial de asistencias
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-body" >
       
                    { !casos ? 
                      <div className="text-center">
                        <h6 className="text-secondary mt-3">Cargando información...</h6>
                        <div className="spinner-border text-secondary" role="status">
                              <span className="visually-hidden">Loading...</span>
                        </div> 
                      </div>  
                      : 
                      casos == 'nada' ? 
                      (
                      <div class="card text-center">
                        <div class="card-body">
                          <h5 class="card-title">No posee casos abiertos</h5>
                          <p class="card-text">¿Desea generar un pedido de asistencia técnica?</p>
                          <Link to='/nuevo' className="btn btn-success"> Nuevo</Link> 

                        </div>
                      </div>
                      )
                      :
                      (
                      <DataTable
                          columns = {colums}
                          data = {casos}
                          pagination
                          paginationComponentOptions={paginationComponentOptions}
                          defaultSortFieldId={1}
                      />)      
                    }  

                </div>
                <div className="card-footer text-muted" >
              
                </div>
            </div>
        </div>
    </div>   
  )
}


