export const msalConfig = {
    auth: {
      clientId: "e7763a70-f743-4d32-8832-e3391632ba00",
      authority: "https://login.microsoftonline.com/f21334b0-75de-4641-8e59-3c0e5a7ab753",
      //redirectUri: "http://localhost:3000",
      //redirectUri: "https://mesadeayuda.pjb.edu.ar/",
      redirectUri: "https://mesadeayuda.educacioncba.edu.ar/"
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };